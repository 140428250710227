<template>
	<div class="insightBox">
		<div class="leftChildNav" :class="showLeft ? '' : 'hide'">
			<div class="topTitle">{{ title[activeR] }}</div>
			<!-- <img class="closeNav"
           @click="changeState({
          prop: 'showLeft',
          value: false
        })"
           v-if="showLeft"
           src="../assets/image/closeNav.png" /> -->
			<div class="fixedLeft" @click="changeFixed">
				<img v-if="fixedLeft" src="../assets/image/fixed_active.png" alt />
				<img v-else src="../assets/image/fixed.png" alt />
			</div>
			<Menu :active-name="activeMenu" @on-select="selectRouter" :accordion="true" @on-open-change="subMenuClick">
				<template v-for="(item, index) in route[activeR]">
					<Submenu :name="'submenu' + item.path" v-if="item.children" :key="index"
						:class="{ 'sub-menu-active': item.path == activeMenu }">
						<template slot="title" class="topNav">
							<!-- <Icon :type="item.icon"
                    size="20" /> -->
							<img :src="item.icon" class="routerIcon">
							<img :src="item.iconW" class="routerIcon">{{ item.title }}</template>
						<template v-for="(item, index) in item.children">
							<MenuItem :key="index" v-if="!item.children" :name="item.path">{{ item.title }}</MenuItem>
							<Submenu :key="index" v-else :name="item.path">
								<template slot="title">{{ item.title }}</template>
								<MenuItem :key="index" v-for="(item, index) in item.children" :name="item.path">
								{{ item.title }}</MenuItem>
							</Submenu>
						</template>
					</Submenu>
					<MenuItem v-else :key="index" :name="item.path">
					<img :src="item.icon" class="routerIcon">
					<img :src="item.iconW" class="routerIcon">{{ item.title }}</MenuItem>
				</template>
			</Menu>
		</div>
		<div class="rightContent">
			<img class="openNav" v-if="showLeft" src="../assets/image/shouqi.png" @click="changeState({
				prop: 'showLeft',
				value: false
			})" />
			<img class="closeNav" v-if="!showLeft" @click="changeState({
				prop: 'showLeft',
				value: true
			})" src="../assets/image/zhankai.png" />
			<router-view />
		</div>
	</div>
</template>

<script>
import {
	getDetail
} from "@/api/MyAccount";
import {
	mapState,
	mapMutations
} from 'vuex'
export default {
	name: 'home',
	data() {
		return {
			routerChildren: [],
			activeR: 'PersonalCenter',
			title: {
				PersonalCenter: '个人中心',
			},
			route: {
				PersonalCenter: [{
					path: '/PersonalCenter/MyAccount',
					title: '我的账号',
					icon: require('@/assets/image/center1.png'),
					iconW: require('@/assets/image/center1W.png'),
				},
				// {
				// 	path: '/PersonalCenter/MyPush',
				// 	title: '我的推送',
				// 	icon: require('@/assets/image/center3.png'),
				// 	iconW: require('@/assets/image/center3W.png'),
				// },
				{
					path: '/PersonalCenter/InfoManage',
					title: '消息',
					icon: require('@/assets/image/center2.png'),
					iconW: require('@/assets/image/center2W.png'),
				},
				],
			}
		};
	},
	computed: {
		...mapState(['showLeft', "fixedLeft"]),
		activeMenu() {
			// let route = this.$route;
			// let path = route.path;
			// // let activeMenu = this.route(this.activeR);
			// return path;
			let route = this.$route;
			let path = route.path;
			let meta = route.meta;
			if (meta && meta.from) {
				path = meta.from;
			}
			// let activeMenu = this.route(this.activeR);
			return path;
		},
		userId() {
			return this.$store.state.userId;
		},
	},
	async mounted() {
		let data = await getDetail(this.userId)
		if (data.userType == 1) {
			// this.route.PersonalCenter.push({
			// 	path: '/PersonalCenter/AccountManage',
			// 	title: '普通账号管理',
			// 	icon: require('@/assets/image/center4.png'),
			// 	iconW: require('@/assets/image/center4W.png'),
			// })
			// this.route.PersonalCenter.push({
			// 	path: '/PersonalCenter/RoleManage',
			// 	title: '角色管理',
			// 	icon: require('@/assets/image/center5.png'),
			// 	iconW: require('@/assets/image/center5W.png'),
			// })
			this.route.PersonalCenter.splice(1, 0, {
				path: '/PersonalCenter/AccountManage',
				title: '普通账号管理',
				icon: require('@/assets/image/center4.png'),
				iconW: require('@/assets/image/center4W.png'),
			}, {
				path: '/PersonalCenter/RoleManage',
				title: '角色管理',
				icon: require('@/assets/image/center5.png'),
				iconW: require('@/assets/image/center5W.png'),
			}
			)

		}
	},
	methods: {
		...mapMutations(['changeState', "changeFixed"]),
		selectRouter(data) {
			if (this.$route.path === data) return;
			if (data.indexOf('noPage') > -1) {
				return
			}
			this.$router.push(data).catch(err => err);
		},
		subMenuClick(arr) {
			if (arr.length) {
				const data = arr.pop().replace('submenu', '')
				data && this.$router.push(data);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";

.insightBox {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	margin: 0 auto;
	background: #fff;
	display: flex;




}
</style>
